
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../assets/homepage_images/hero.json";
const LandingPage = () => {
     const options = {
    animationData: groovyWalkAnimation,
    loop: true
  };
  const { View } = useLottie(options);
    return (
        <section id='landing'>
            <div className='px-4 py-5 my-5 text-center'>
                <div className='lottie-container d-flex justify-content-center'>
                    <div className='lottie '>
                        {View}
                    </div>
                </div>
                
                <h1 className='display-5 fw-bold landing-text'>
                    Web Developer
                </h1>
                <div className='col-lg-6 mx-auto'>
                    <p className='lead mb-4 fs-6'>Hello, I'm from the Philippines and I specialize in developing online systems and applications using MERN. I've recently enrolled in a coding bootcamp for personal development.</p>
                    <div className='d-grid gap-2 d-sm-flex justify-content-sm-center'>
                        <a className='View' href='http://localhost:3000/#projects' >View Projects </a> <p>or</p><a  className='View'  href='/about_me' >Read more about me </a>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default LandingPage;